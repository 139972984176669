import AboutSection from "../components/AboutComponents/AboutComponent";
import FactsSection from "../components/HomeComponents/FactsSectionComponent";
import { Helmet } from "react-helmet-async";
import WelcomeSection from "../components/WelcomeComponent";
import ValuesSection from "../components/ServicesComponents/ValuesComponent";
import DetailsSection from "../components/AboutComponents/DetailsComponent";
import JourneySection from "../components/AboutComponents/JourneyComponent";

function AboutPage() {
  return (
    <>
      <Helmet>
        <title>Rawafid</title>
      </Helmet>
      <WelcomeSection name={"About Us"} />
      <AboutSection />
      <JourneySection />
      <DetailsSection />
      {/* <hr></hr> */}
      {/* <ValuesSection /> */}
      {/* <FactsSection /> */}
    </>
  );
}

export default AboutPage;
