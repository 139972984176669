import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function WelcomeSection({ name }: any) {
  return (
    <div id="banner-area" className="banner-area welcome-header">
      <div className="banner-text">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="banner-heading">
                <h1 className="banner-title">{name}</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
