export default function ValuesSection() {
  const services = [
    {
      title: "Diversity",
      desc: "Offering a wide range of expertise to meet the multifaceted needs of our clients and overcome the challenges of various sectors.",
    },
    {
      title: "Integrity",
      desc: "Conducting business with honesty, transparency, and respecting our clients, partners, and employees.",
    },

    {
      title: "Excellence",
      desc: "Achieving the highest quality in every project, and striving to consistently deliver superior results that stand the test of time.",
    },
    {
      title: "Collaboration",
      desc: "Building strong partnerships across the globe by combining the strengths of our multidisciplinary team..",
    },
    {
      title: "Sustainability",
      desc: "Committing to environmentally responsible practices from projects.",
    },
  ];

  return (
    <section id="ts-features" className="ts-features pb-2 ">
      <div className="container">
        <h2 className="headerH">Our Values</h2>
        <div className="row mt-5 d-flex justify-content-center">
          {services.map((item, index) => (
            <div key={index} className="col-lg-4 col-md-6 mb-5 ">
              <div className="value-box">
                <div className="d-flex">
                  <div className="ts-service-info">
                    <h3 className="service-box-title">{item.title}</h3>
                    <p>{item.desc}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
