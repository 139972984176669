export default function TeamSection() {
  return (
    <section id="main-container" className="main-container section-bg">
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">
          {/* <div className="col-lg-6 mt-5 mt-lg-0 d-flex justify-content-center">
            <img
              src="../assets/images/services/service1.jpg"
              alt="Service"
              width={"90%"}
            />
          </div> */}
          <div style={{ textAlign: "center" }} className="col-lg-10">
            <h3 style={{ marginBottom: "5px" }}>Our Team</h3>
            <h5 className="column-title">
              Diverse Expertise for Comprehensive Solutions
            </h5>
            <p style={{ fontSize: "18px", lineHeight: "1.9rem" }}>
              At Rawafid, our strength lies in the diversity and expertise of
              our team. With professionals from various sectors including
              energy, maritime, finance, and real estate, we bring a wealth of
              knowledge and experience to every project.
              <br></br>
              <br></br>
              Our team members are not only experts in their respective fields
              but also share a commitment to innovation, sustainability, and
              excellence.
              <br></br>
              <br></br>
              Together, we work towards achieving Rawafid's vision of global
              leadership in commerce and infrastructure development.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
