export default function ReachUsSection() {
  return (
    <section id="main-container" className="main-container">
      <div className="container">
        <div className="row text-center">
          <div style={{ textAlign: "left" }} className="col-12">
            <p style={{ fontSize: "20px", lineHeight: "1.9rem" }}>
              <b>Get in touch </b> with Rawafid for inquiries, feedback, project
              requests, or collaborations. Our team is ready to answer your
              questions and assist you with expert guidance and support.
              <br></br>
              <br></br> Reach out to us and start your journey today!
            </p>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-4 mt-2">
            <div className="ts-service-box-bg text-center h-100">
              <span className="ts-service-icon icon-round">
                <i className="fas fa-map-marker-alt mr-0" />
              </span>
              <div className="ts-service-box-content">
                <h4>Our Office</h4>
                <p>
                  Lebanon - Beirut - Beit Merri <br></br> Salameh Building -
                  Ground Floor
                </p>
              </div>
            </div>
          </div>
          {/* Col 1 end */}
          <div className="col-md-4 mt-2">
            <div className="ts-service-box-bg text-center h-100">
              <span className="ts-service-icon icon-round">
                <i className="fa fa-envelope mr-0" />
              </span>
              <div className="ts-service-box-content">
                <h4>Email Us</h4>
                <a style={{ color: "white" }} href="mailto:info@rawafidsal.com">
                  info@rawafidsal.com
                </a>
              </div>
            </div>
          </div>
          {/* Col 2 end */}
          <div className="col-md-4 mt-2">
            <div className="ts-service-box-bg text-center h-100">
              <span className="ts-service-icon icon-round">
                <i className="fa fa-phone-square mr-0" />
              </span>
              <div className="ts-service-box-content">
                <h4>Call Us</h4>
                <a href="tel:+96181713519">+961 8171 3519</a>
              </div>
            </div>
          </div>
        </div>
        <div className="gap-60" />
        {/* <hr></hr> */}
        {/* 
        <div className="row d-flex justify-content-center">
          <div className="col-md-8">
            <h3 className="column-title">We love to hear</h3>
            <form id="contact-form" action="#" method="post" role="form">
              <div className="error-container" />
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      className="form-control form-control-name"
                      name="name"
                      id="name"
                      placeholder=""
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      className="form-control form-control-email"
                      name="email"
                      id="email"
                      placeholder=""
                      type="email"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Subject</label>
                    <input
                      className="form-control form-control-subject"
                      name="subject"
                      id="subject"
                      placeholder=""
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Message</label>
                <textarea
                  className="form-control form-control-message"
                  name="message"
                  id="message"
                  placeholder=""
                  rows={10}
                  required
                  defaultValue={""}
                />
              </div>
              <div className="text-right">
                <br />
                <button className="btn btn-primary solid blank" type="submit">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div> */}
      </div>
    </section>
  );
}
