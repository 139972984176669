export default function FooterSection() {
  return (
    <footer id="footer" className="footer bg-overlay">
      <div className="footer-main">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4 col-md-6 footer-widget footer-about">
              <h3 className="widget-title">About Us</h3>

              <p>
                Powering the Future Via Innovating Solutions Creating
                Sustainable Solutions to Transform Infrastructure, Empowering
                Communities, and Enhancing Efficiency!
              </p>
              {/* <div className="footer-social">
                <ul>
                  <li>
                    <a
                      href="https://facebook.com/themefisher"
                      aria-label="Facebook"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/themefisher"
                      aria-label="Twitter"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/themefisher"
                      aria-label="Instagram"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/themefisher"
                      aria-label="Github"
                    >
                      <i className="fab fa-github" />
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="col-lg-4 col-md-6 footer-widget mt-5 mt-md-0">
              <h3 className="widget-title">Contact Us</h3>
              <div className="working-hours">
                {/* We work 7 days a week, every day excluding major holidays.
                Contact us if you have an emergency, with our Hotline and
                Contact form.
                <br />
                <br />  */}
                <p className="mb-2">
                  <i className="fa fa-phone-alt me-3 icon"></i>
                  <a
                    className="ml-2"
                    style={{ color: "white" }}
                    href="tel:+96181713519"
                  >
                    +961 8171 3519
                  </a>
                </p>
                <p className="mb-2">
                  <i className="fa fa-envelope me-3 icon"></i>
                  <a
                    className="ml-2"
                    style={{ color: "white" }}
                    href="mailto:info@rawafidsal.com"
                  >
                    info@rawafidsal.com
                  </a>
                </p>
                <p className="mb-2">
                  <i className="fa fa-map-pin me-3 icon"></i>
                  <a className="ml-2" style={{ color: "white" }}>
                    Lebanon - Beirut - Beit Merri <br></br> Salameh Building -
                    Ground Floor
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mt-5 mt-lg-0 footer-widget d-flex align-items-center">
              <img
                loading="lazy"
                className="footer-logo"
                src="../assets/images/logo.png"
                alt="Constra"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="copyright-info text-center">
                <span>© Rawafid 2024, All Right Reserved.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
